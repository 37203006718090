import React from 'react';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { BaseImage } from '@alterpage/gatsby-plugin-image';

import {
    container,
    wrapper,
    title,
    content,
    results,
    resultsSide,
    resultsDivider,
    score,
    button as buttonClass,
    header,
} from './candidate-recommendation-card.module.scss';

import ArrowLeft from '../../assets/images/svg/candidate-arrow-left.svg';
import ArrowRight from '../../assets/images/svg/candidate-arrow-right.svg';

import useTranslations from '../../hooks/use-translations';

import Button, { TButtonStylePreset } from '../atoms/button';

interface ICandidateRecommendationCardProps {
    className?: string;
    card: {
        title: string;
        score: number;
        image: { ImageDataLike: ImageDataLike; name: string } | undefined;
        content?: string;
        left?: string;
        right?: string;
    };
    button?: {
        title: string;
        to: string;
        style: string;
        type?: string;
    };
}

const CandidateRecommendationCard: React.FC<ICandidateRecommendationCardProps> = ({
    className = '',
    card,
    button,
}) => {
    const t = useTranslations('CandidateRecommendationCard');

    return (
        <div className={`${className} ${container}`}>
            <div className={wrapper}>
                <div className={score}>{card.score}%</div>
                {card.image && <BaseImage image={getImage(card.image)} />}
                {button && (
                    <Button
                        as={'link'}
                        className={buttonClass}
                        to={button.to}
                        stylePreset={button.style as TButtonStylePreset}
                    >
                        {button.title}
                    </Button>
                )}
            </div>
            <p className={title}>{card.title}</p>
            {card.content && <p className={content}>{card.content}</p>}
            {card.left && card.right && (
                <div className={results}>
                    <div className={resultsSide}>
                        <p className={header}>{t.low}</p>
                        <p className={content}>{card.left}</p>
                    </div>
                    <div className={resultsDivider}>
                        <ArrowLeft />
                        <ArrowRight />
                    </div>
                    <div className={resultsSide}>
                        <p className={header}>{t.high}</p>
                        <p className={content}>{card.right}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CandidateRecommendationCard;
