// extracted by mini-css-extract-plugin
export var backgroundAnimation = "candidate-recommendation-card-module--backgroundAnimation--40e6b";
export var button = "candidate-recommendation-card-module--button--4269c";
export var container = "candidate-recommendation-card-module--container--a02fc";
export var content = "candidate-recommendation-card-module--content--12358";
export var header = "candidate-recommendation-card-module--header--a4bc2";
export var results = "candidate-recommendation-card-module--results--77fbf";
export var resultsDivider = "candidate-recommendation-card-module--results-divider--718b8";
export var resultsSide = "candidate-recommendation-card-module--results-side--9a251";
export var score = "candidate-recommendation-card-module--score--945e8";
export var title = "candidate-recommendation-card-module--title--0ac4f";
export var wrapper = "candidate-recommendation-card-module--wrapper--6c703";