// extracted by mini-css-extract-plugin
export var backgroundAnimation = "candidate-recommendations-module--backgroundAnimation--47e84";
export var button = "candidate-recommendations-module--button--4d703";
export var buttons = "candidate-recommendations-module--buttons--37b67";
export var cardsWrapper = "candidate-recommendations-module--cards-wrapper--01831";
export var container = "candidate-recommendations-module--container--7f9fd";
export var darkCard = "candidate-recommendations-module--dark-card--bd868";
export var grid = "candidate-recommendations-module--grid--b057a";
export var header = "candidate-recommendations-module--header--e428b";
export var image = "candidate-recommendations-module--image--da7d5";
export var lightCard = "candidate-recommendations-module--light-card--698f2";
export var loader = "candidate-recommendations-module--loader--5d7ed";
export var loading = "candidate-recommendations-module--loading--232df";
export var retryBox = "candidate-recommendations-module--retry-box--61617";
export var section = "candidate-recommendations-module--section--2ab1b";
export var sectionBox = "candidate-recommendations-module--section-box--b8a36";
export var sectionSubtitle = "candidate-recommendations-module--section-subtitle--c322a";
export var sectionTitle = "candidate-recommendations-module--section-title--fd304";
export var title = "candidate-recommendations-module--title--cc712";