import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { IQueryAllResult, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { BaseImage } from '@alterpage/gatsby-plugin-image';

import {
    sectionBox,
    grid,
    retryBox,
    header,
    title,
    container,
    image,
    section as sectionClass,
    sectionTitle,
    sectionSubtitle,
    buttons,
    button as buttonClass,
    loader,
    cardsWrapper,
    lightCard,
    darkCard,
} from './candidate-recommendations.module.scss';
import { ISection } from '../../models/section.model';
import { IPage } from '../../models/page.model';
import { TLocale } from '../../locale';
import { IBryqResults } from '../../models/bryq-results.model';
import { getNodes } from '../../utils/get-nodes';
import { useCandidate } from '../../hooks/use-candidate';

import Section from '../hoc/section';
import useTranslations from '../../hooks/use-translations';
import Button, { TButtonStylePreset } from '../atoms/button';
import Loader from '../atoms/loader';
import CandidateRecommendationCard from '../molecules/candidate-recommendation-card';
import CandidateTestButton from '../atoms/candidate-test-button';

interface ICandidateRecommendationsProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

interface ICandidateRecommendationsQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type' | 'locale'>>;
    allFile: IQueryAllResult<{ ImageDataLike: ImageDataLike; name: string }>;
}

const CandidateRecommendations: React.FC<ICandidateRecommendationsProps> = ({
    section,
    TitleTag = 'h2',
}) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('CandidateRecommendations');
    const { locale } = usePageContext();
    const candidate = useCandidate({ queries: ['recommendation'] });
    const { allPage, allFile } = useStaticQuery<ICandidateRecommendationsQueryResult>(query);
    const pages = getNodes(allPage);
    const banner = getNodes(allFile)?.filter((file) => file.name === 'recommendation-banner');
    const images = getNodes(allFile)?.filter((file) => file.name !== 'recommendation-banner');
    const results: IBryqResults = candidate.recommendation.data?.results.details;
    const sections = getSections(results, t, pages, images, locale);

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                {!candidate.data?.testRetryWaitForAdmin && (
                    <div className={retryBox}>
                        <p>{t.retry}</p>
                        <CandidateTestButton />
                    </div>
                )}
                <div className={header}>
                    <TitleTag className={title}>{t.title}</TitleTag>
                </div>
                <BaseImage className={image} image={getImage(banner[0])} />
                {sections &&
                    sections.length > 0 &&
                    sections.map((section) => {
                        const moreButton = section.buttons.find((button) => button.type === 'more');

                        return (
                            <div className={sectionClass}>
                                <h2 className={sectionTitle}>{section.title}</h2>
                                <p className={sectionSubtitle}>{section.subtitle}</p>
                                <div className={cardsWrapper}>
                                    {section.cards.map((card) => {
                                        return (
                                            <CandidateRecommendationCard
                                                card={card}
                                                button={moreButton}
                                                className={
                                                    section.theme === 'light' ? lightCard : darkCard
                                                }
                                            />
                                        );
                                    })}
                                </div>
                                {section.buttons.length > 1 && (
                                    <div className={buttons}>
                                        {section.buttons.map((button) => {
                                            if (button.type === 'more') return null;

                                            return (
                                                <Button
                                                    className={buttonClass}
                                                    stylePreset={
                                                        `${button.style}` as TButtonStylePreset
                                                    }
                                                    as={'link'}
                                                    to={button.to}
                                                >
                                                    {button.title}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                {!sections && <Loader className={loader} />}
            </div>
        </Section>
    );
};

const getSections = (
    results: IBryqResults,
    t: TLocale['CandidateRecommendations'],
    pages: Pick<IPage, 'type' | 'locale' | 'pathname'>[],
    images: { ImageDataLike: ImageDataLike; name: string }[],
    locale: string
) => {
    const candidateFaqPage =
        pages.find((page) => page.type === 'candidate-faq' && page.locale === locale)?.pathname ||
        '';
    const candidateSuggestionsPage =
        pages.find((page) => page.type === 'candidate-suggestions' && page.locale === locale)
            ?.pathname || '';
    const positionsMatchPage =
        pages.find((page) => page.type === 'candidate-positions' && page.locale === locale)
            ?.pathname || '';

    if (!results) return null;

    return [
        {
            theme: 'light',
            title: t.sections.one.title,
            subtitle: t.sections.one.subtitle,
            cards: [
                {
                    title: t.sections.one.cards.one.title,
                    content: t.sections.one.cards.one.content,
                    score: results.indicators['Big 5 (OCEAN)'].groups.Openness,
                    image: images.find((image) => image.name === 'recommendation-1'),
                },
                {
                    title: t.sections.one.cards.two.title,
                    content: t.sections.one.cards.two.content,
                    score: results.indicators['Big 5 (OCEAN)'].groups.Conscientiousness,
                    image: images.find((image) => image.name === 'recommendation-2'),
                },
                {
                    title: t.sections.one.cards.three.title,
                    content: t.sections.one.cards.three.content,
                    score: results.indicators['Big 5 (OCEAN)'].groups.Agreeableness,
                    image: images.find((image) => image.name === 'recommendation-3'),
                },
                {
                    title: t.sections.one.cards.four.title,
                    content: t.sections.one.cards.four.content,
                    score: results.indicators['Big 5 (OCEAN)'].groups.Extraversion,
                    image: images.find((image) => image.name === 'recommendation-4'),
                },
                {
                    title: t.sections.one.cards.five.title,
                    content: t.sections.one.cards.five.content,
                    score: results.indicators['Big 5 (OCEAN)'].groups.Neuroticism,
                    image: images.find((image) => image.name === 'recommendation-5'),
                },
            ],
            buttons: [
                {
                    type: 'more',
                    title: t.more,
                    to: candidateFaqPage,
                    style: 'secondary',
                },
            ],
        },
        {
            theme: 'dark',
            title: t.sections.two.title,
            subtitle: t.sections.two.subtitle,
            cards: [
                {
                    title: t.sections.two.cards.one.title,
                    content: t.sections.two.cards.one.content,
                    score: results.indicators.Communication.score,
                    image: images.find((image) => image.name === 'recommendation-6'),
                },
                {
                    title: t.sections.two.cards.two.title,
                    content: t.sections.two.cards.two.content,
                    score: results.indicators['Emotional Intelligence'].score,
                    image: images.find((image) => image.name === 'recommendation-7'),
                },
                {
                    title: t.sections.two.cards.three.title,
                    content: t.sections.two.cards.three.content,
                    score: results.indicators['Problem Solving / Complex Thinking'].score,
                    image: images.find((image) => image.name === 'recommendation-8'),
                },
                {
                    title: t.sections.two.cards.four.title,
                    content: t.sections.two.cards.four.content,
                    score: results.indicators.Resilience.score,
                    image: images.find((image) => image.name === 'recommendation-9'),
                },
                {
                    title: t.sections.two.cards.five.title,
                    content: t.sections.two.cards.five.content,
                    score: results.indicators['Safety Behavior'].score,
                    image: images.find((image) => image.name === 'recommendation-10'),
                },
            ],
            buttons: [
                {
                    type: 'more',
                    title: t.more,
                    to: candidateFaqPage,
                    style: 'secondary',
                },
                {
                    title: t.match,
                    to: positionsMatchPage,
                    style: 'cta',
                },
                {
                    title: t.inform,
                    to: candidateSuggestionsPage,
                    style: 'secondary',
                },
            ],
        },
        {
            theme: 'light',
            title: t.sections.three.title,
            subtitle: t.sections.three.subtitle,
            cards: [
                {
                    title: t.sections.three.cards.one.title,
                    content: t.sections.three.cards.one.content,
                    score: results['Attention to detail'],
                    image: images.find((image) => image.name === 'recommendation-11'),
                },
                {
                    title: t.sections.three.cards.two.title,
                    content: t.sections.three.cards.two.content,
                    score: results['Logical reasoning'],
                    image: images.find((image) => image.name === 'recommendation-12'),
                },
                {
                    title: t.sections.three.cards.three.title,
                    content: t.sections.three.cards.three.content,
                    score: results['Numerical reasoning'],
                    image: images.find((image) => image.name === 'recommendation-13'),
                },
                {
                    title: t.sections.three.cards.four.title,
                    content: t.sections.three.cards.four.content,
                    score: results['Verbal reasoning'],
                    image: images.find((image) => image.name === 'recommendation-14'),
                },
            ],
            buttons: [
                {
                    type: 'more',
                    title: t.more,
                    to: candidateFaqPage,
                    style: 'secondary',
                },
                {
                    title: t.match,
                    to: positionsMatchPage,
                    style: 'cta',
                },
                {
                    title: t.inform,
                    to: candidateSuggestionsPage,
                    style: 'secondary',
                },
            ],
        },
        {
            theme: 'dark',
            title: t.sections.four.title,
            subtitle: t.sections.four.subtitle,
            cards: [
                {
                    title: t.sections.four.cards.one.title,
                    subtitle: t.sections.four.cards.one.subtitle,
                    left: t.sections.four.cards.one.left,
                    right: t.sections.four.cards.one.right,
                    score: results['Work style'],
                    image: images.find((image) => image.name === 'recommendation-15'),
                },
                {
                    title: t.sections.four.cards.two.title,
                    left: t.sections.four.cards.two.left,
                    right: t.sections.four.cards.two.right,
                    score: results['Social skills'],
                    image: images.find((image) => image.name === 'recommendation-16'),
                },
                {
                    title: t.sections.four.cards.three.title,
                    left: t.sections.four.cards.three.left,
                    right: t.sections.four.cards.three.right,
                    score: results['Personality'],
                    image: images.find((image) => image.name === 'recommendation-17'),
                },
                {
                    title: t.sections.four.cards.four.title,
                    left: t.sections.four.cards.four.left,
                    right: t.sections.four.cards.four.right,
                    score: results.indicators['Team Player'].score,
                    image: images.find((image) => image.name === 'recommendation-18'),
                },
            ],
            buttons: [
                {
                    type: 'more',
                    title: t.more,
                    to: candidateFaqPage,
                    style: 'secondary',
                },
                {
                    title: t.match,
                    to: positionsMatchPage,
                    style: 'cta',
                },
                {
                    title: t.inform,
                    to: candidateSuggestionsPage,
                    style: 'secondary',
                },
                {
                    title: t.faq,
                    to: candidateFaqPage,
                    style: 'secondary',
                },
            ],
        },
    ];
};

export const query = graphql`
    query {
        allPage(
            filter: {
                type: { in: ["candidate-faq", "candidate-suggestions", "candidate-positions"] }
            }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
        allFile(filter: { name: { regex: "/^recommendation.*/" } }) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
    }
`;

export default CandidateRecommendations;
